import { IRedirectProducts } from "../../utils/sergipao";
import "./redirectCard.sass";

function importAll(r: any) {
  let images: any = {};
  r.keys().map((item: any, index: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../images/products/", false, /\.(png|jpe?g|svg)$/)
);

export default function RedirectCard(props: { data: IRedirectProducts }) {
  const { data } = props;

  const isPromotional = data.name.includes("Smart Mini");

  return (
    <a href={data.path}>
      <div
        className="containerRedirectCard"
        style={{
          backgroundColor: data.background,
        }}
      >
        <img
          className="imgRedirectCard"
          src={images[data.image + ".png"]}
          alt={data.name}
        ></img>
        <div className="infoRedirectCard">
        {isPromotional && <span className="promotionalText">De R$ 59,90 Por</span>}
          <span className="nameRedirectCard">{data.name}</span>
          <span className="priceRedirectCard">
            <span
              style={{
                fontSize: "1.5rem",
              }}
            >
              R${" "}
            </span>
            {data.price.toFixed(2).replace(".", ",")}
            <span
              style={{
                fontSize: "1.5rem",
              }}
            >
              /mês
            </span>
          </span>
        </div>
      </div>
    </a>
  );
}
